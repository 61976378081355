'use strict';

angular.module('windmanagerApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('printReport', {
        url: '/print-report/:id',
        templateUrl: 'app/print/print-report/print-report.html',
        controller: 'printReportController',
        controllerAs: 'pt',
        data: {
          bodyClass: ''
        }
      })
  })
  .run(function($rootScope) {
    $rootScope.$on('$stateChangeStart', function(event, next, nextParams, current) {
      if (next.name === 'logout' && current && current.name && !current.authenticate) {
        next.referrer = current.name;
      }
    });
  });
